<template>
  <div class="card-container">
    <div v-for="game in games" :key="game.id" class="card">
      <img :src="game.image" alt="Game Image" @click="openGame(game.url)">
      <p>{{ game.description }}</p>
    </div>
  </div>
  <footer class="footer">
          <a href="https://beian.miit.gov.cn/" target="_blank">备案号：京ICP备2022021060号-1</a>
    <img src="../assets/beian.png" alt="图标" style="width: 20px; height: 20px; vertical-align: middle; margin-right: 5px;" />

    <a href="https://beian.mps.gov.cn/#/query/webSearch?code=11011302007352" rel="noreferrer" target="_blank">京公网安备11011302007352</a>
  </footer>
</template>

<script>
export default {
  name: 'MyGames',
  data() {
    return {
      games: [
        { id: 1, image: '/images/abc.jpg', description: '这是游戏1', url: 'https://mingxuanmowang.top/awula/index.html' },
        { id: 2, image: 'https://gips2.baidu.com/it/u=3793389349,1404575344&fm=3039&app=3039&f=PNG?w=1024&h=1024', description: '这是游戏2', url: 'https://gips2.baidu.com/it/u=3793389349,1404575344&fm=3039&app=3039&f=PNG?w=1024&h=1024' }
      ]
    }
  },
  methods: {
    openGame(url) {
      window.open(url, '_blank');
    }
  }
}
</script>

<style>
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}
.card {
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgb(0,0,0,0.1);
  width: 300px;
  margin: 10px;
  transition: transform 0.3s ease-in-out;
}
.card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.card p {
  padding: 10px;
  margin: 0;
  font-size: 16px;
  color: #333;
}
.card:hover {
  transform: scale(1.05);
}
.footer {
  text-align: center;
  padding: 20px;
  color: #666;
  background-color: #f9f9f9;
  width: 100%;
  box-sizing: border-box;
}
</style>
